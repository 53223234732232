import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Breadcrumbs from '../components/breadcrumbs'

const PlaybookPage = () => {
  const data = useStaticQuery(graphql`
    query PlaybookPageQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const socialImage = 'https://www.chrisdpeters.com/images/social/chrisdpeters-summary.png'
  const socialImageAlt = `Chris Peters`

  const description = `Website and content how-to guides, battle-tested ` +
    `across many years and projects.`

  return (
    <Layout currentSection="playbook">
      <SEO
        title="Playbook"
        description={description}
        canonicalUrl={`${data.site.siteMetadata.siteUrl}/`}
        meta={[
          {
            property: 'og:image',
            content: socialImage,
          },
          {
            property: 'og:image:alt',
            content: socialImageAlt,
          },
          {
            name: 'twitter:creator',
            content: '@chrisdpeters',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: socialImage,
          },
          {
            name: 'twitter:image:alt',
            content: socialImageAlt,
          },
        ]}
      />

      <Section>
        <Container>
          <Row className="justify-content-around">
            <Col xs={12} lg={8} className="mb-4">
              <Breadcrumbs />

              <h1>
                Playbook
              </h1>
              <p className="lead">
                Website and content how-to guides, battle-tested across many
                years and projects.
              </p>
              <p>
                Hat tip: this playbook is inspired by thoughbot's wonderful{' '}
                <a href="http://playbook.thoughtbot.com/">Playbook</a>.
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center mb-5">
            <Col xs={12} sm={6} lg={4}>
              <h3 className="h5 pt-lg-3">
                Building and running websites
              </h3>
              <nav>
                <ul className="list-unstyled">
                  <li className="mb-1">
                    <Link to="/playbook/google-analytics-setup/">
                      Google Analytics Setup
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link to="/playbook/sass-css-style-guide/">
                      Sass and CSS Style Guide
                    </Link>
                  </li>
                  {/*
                  <li>
                    <Link to="/uses/">
                      Tools I Use
                    </Link>
                  </li>
                  */}
                </ul>
              </nav>
            </Col>

            <Col xs={12} sm={6} lg={4}>
              <h3 className="h5 pt-lg-3">
                Web content best practices
              </h3>
              <nav>
                <ul className="list-unstyled">
                  <li className="mb-1">
                    <Link to="/playbook/about-us-pages/">
                      About Us Pages
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link to="/playbook/contact-us-pages/">
                      Contact Us Pages
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link to="/playbook/press-media-pages/">
                      Press &amp; Media Pages
                    </Link>
                  </li>
                  <li>
                    <Link to="/playbook/web-content-guidelines/">
                      Web Content Guidelines
                    </Link>
                  </li>
                </ul>
              </nav>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} lg={8}>
              <h2 className="h3">
                Related resources
              </h2>
              <ul>
                <li>
                  <a href="http://playbook.thoughtbot.com/">The Playbook</a>,
                  thoughtbot
                </li>
                <li>
                  <a href="https://blog.chrisdpeters.com/introducing-playbook-permanent-blog-posts/">
                  Introducing Playbook: more permanent than blog posts</a>,
                  Chris Peters
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default PlaybookPage
